<script>
  import { ArrowRight } from "lucide-svelte";
  import { cubicOut } from "svelte/easing";
  import { slide } from "svelte/transition";

  import { analytics } from "$lib/analytics";
  import { isMobileMenuOpen } from "$lib/mobile-menu";
  import FilloutEmbed from "$lib/ui/marketing/FilloutEmbed.svelte";

  let startupCreditsFormOpen = false;
</script>

{#if !$isMobileMenuOpen}
  <div
    class="py-2 px-4 flex flex-col sm:flex-row justify-center bg-brand-green text-black text-sm font-medium"
    transition:slide={{ duration: 300, easing: cubicOut }}
  >
    <div class="flex flex-col sm:flex-row sm:gap-x-4">
      <span>Startups get up to $50k in free compute credits.</span>
      <span class="flex items-center gap-x-1">
        <button
          on:click={() => {
            analytics.track("Announcement Banner Startup Credits Form Clicked");
            startupCreditsFormOpen = true;
          }}
          class="underline">Apply now</button
        >
        <ArrowRight size="16" />
      </span>
    </div>
  </div>
{/if}

<FilloutEmbed
  formId="j9fukSoQTius"
  title="Startup credits form"
  open={startupCreditsFormOpen}
  on:close={() => (startupCreditsFormOpen = false)}
/>
